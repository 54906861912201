import "./styles.scss"

import React from "react"

const HeroSubpage = ({ title, description, backgroundImage }) => (
  <section className="hero-subpage">
    <div className="container-fluid">
      <h2>{title}</h2>
      <div
        className="hero-subpage__content"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
    {backgroundImage && (
      <div className="hero-subpage__image">
        <img src={backgroundImage} alt="" />
      </div>
    )}
  </section>
)

export default HeroSubpage
